import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "users",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "list",
        title: "List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/list"
      }
    ]
  },
  {
    id: "profile",
    title: "Profile",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "accountSettings",
        title: "Account Settings",
        type: "item",
        icon: <Icon.Settings size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/profile/account-settings"
      }
    ]
  }
]

export default navigationConfig
